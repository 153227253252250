
import { defineComponent } from "vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";

export default defineComponent({
  name: "ForgotPasswordConfirm",
  components: {
    FlatButton,
  },
  props: {
    email: {
      type: String,
      default: localStorage.getItem("emailForgotPassword"),
    },
  },

  methods: {
    loginPage() {
      this.$router.push({ name: "Login" });
    },
  },
});
